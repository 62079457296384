/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const ReviewHalfIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            d="M9.184 1.91a1.019 1.019 0 0 1 1.632 0l2.677 3.614c.125.169.3.294.501.36l4.31 1.396c.65.211.905.985.504 1.533l-2.655 3.631a.99.99 0 0 0-.191.582l-.014 4.478c-.002.676-.669 1.154-1.32.947l-4.318-1.37a1.024 1.024 0 0 0-.62 0l-4.317 1.37c-.652.207-1.32-.271-1.321-.947l-.014-4.478a.99.99 0 0 0-.191-.582l-2.655-3.63a.994.994 0 0 1 .504-1.534l4.31-1.397c.2-.065.376-.19.5-.359L9.185 1.91ZM10 4.19v10.845c.313 0 .627.048.929.144l3 .953.01-3.112a2.97 2.97 0 0 1 .575-1.745l1.845-2.523-2.996-.971a3.032 3.032 0 0 1-1.502-1.078L10 4.19Z"
        />
    </svg>
);
const ForwardRef = forwardRef(ReviewHalfIcon);
export default ForwardRef;
