/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const ReferenceOutlineIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M13.053 1h-2.725c-.867 0-1.571.704-1.571 1.571v4.352a.393.393 0 0 1-.588.341l-3.835-2.19a1.57 1.57 0 0 0-2.136.572L1.214 7.33c-.44.756-.18 1.724.578 2.156l3.802 2.172a.394.394 0 0 1 0 .684l-3.802 2.171a1.572 1.572 0 0 0-.578 2.156l.984 1.686a1.57 1.57 0 0 0 2.136.572l3.835-2.192a.393.393 0 0 1 .588.342v4.352c0 .867.704 1.571 1.572 1.571h2.723c.869 0 1.572-.704 1.572-1.571v-4.352a.393.393 0 0 1 .588-.342l3.835 2.192a1.57 1.57 0 0 0 2.137-.572l.983-1.686c.44-.755.18-1.723-.578-2.155l-3.802-2.172a.394.394 0 0 1 0-.684l3.802-2.171a1.571 1.571 0 0 0 .578-2.157l-.983-1.685a1.57 1.57 0 0 0-2.137-.572l-3.835 2.191a.393.393 0 0 1-.588-.342v-4.35c0-.868-.703-1.572-1.572-1.572Zm0 1.466c.057 0 .104.048.104.105v4.352a1.862 1.862 0 0 0 2.782 1.615l3.835-2.192a.104.104 0 0 1 .142.038L20.9 8.07c.007.01.023.039.011.081-.01.042-.04.058-.049.064l-3.801 2.171c-.578.33-.937.949-.937 1.615 0 .665.36 1.285.937 1.615l3.801 2.171c.01.006.038.022.05.064a.098.098 0 0 1-.012.08l-.983 1.686a.104.104 0 0 1-.09.052.096.096 0 0 1-.052-.015l-3.835-2.191a1.853 1.853 0 0 0-2.211.274c-.368.353-.571.83-.571 1.341v4.352a.105.105 0 0 1-.104.105h-2.725a.105.105 0 0 1-.104-.105v-4.352a1.863 1.863 0 0 0-2.782-1.615l-3.835 2.191a.092.092 0 0 1-.05.015.105.105 0 0 1-.093-.052l-.982-1.686a.1.1 0 0 1-.011-.08c.01-.042.04-.058.049-.064l3.801-2.171c.578-.33.937-.95.937-1.615 0-.666-.36-1.285-.937-1.615L2.52 8.214c-.01-.006-.038-.022-.05-.064-.01-.042.006-.07.012-.08l.982-1.686a.105.105 0 0 1 .092-.052c.018 0 .034.005.05.014l3.836 2.192a1.862 1.862 0 0 0 2.782-1.615V2.57c0-.057.046-.105.104-.105h2.829"
            clipRule="evenodd"
        />
    </svg>
);
const ForwardRef = forwardRef(ReferenceOutlineIcon);
export default ForwardRef;
