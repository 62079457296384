/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const ReviewUnfilledIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            d="m10 4.19 1.86 2.513c.375.505.9.882 1.504 1.078l2.995.97-1.846 2.524a2.97 2.97 0 0 0-.573 1.745l-.01 3.112-3.001-.953a3.072 3.072 0 0 0-1.858 0l-3 .953-.01-3.112a2.97 2.97 0 0 0-.574-1.745L3.64 8.752l2.996-.971a3.032 3.032 0 0 0 1.502-1.078L10 4.19Zm.816-2.28a1.019 1.019 0 0 0-1.632 0L6.507 5.523a1.01 1.01 0 0 1-.501.36L1.696 7.28a.994.994 0 0 0-.504 1.533l2.655 3.631a.99.99 0 0 1 .191.582l.014 4.478c.002.676.669 1.154 1.32.947l4.318-1.37c.202-.064.418-.064.62 0l4.317 1.37c.652.207 1.32-.271 1.321-.947l.014-4.478a.99.99 0 0 1 .191-.582l2.655-3.63a.994.994 0 0 0-.504-1.534l-4.31-1.397a1.01 1.01 0 0 1-.5-.359L10.815 1.91Z"
        />
    </svg>
);
const ForwardRef = forwardRef(ReviewUnfilledIcon);
export default ForwardRef;
