/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const ReferenceIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="m22.167 16.67-.983 1.685c-.435.745-1.389 1-2.137.573l-3.835-2.193a.393.393 0 0 0-.588.342v4.352c0 .868-.704 1.571-1.572 1.571H10.33a1.571 1.571 0 0 1-1.572-1.571v-4.352a.393.393 0 0 0-.588-.342l-3.835 2.193a1.572 1.572 0 0 1-2.137-.573l-.983-1.686a1.572 1.572 0 0 1 .578-2.155l3.802-2.173a.393.393 0 0 0 0-.682L1.792 9.486a1.572 1.572 0 0 1-.578-2.156l.983-1.685c.435-.745 1.388-1 2.137-.572L8.17 7.264c.263.15.588-.04.588-.34V2.57c0-.867.704-1.571 1.572-1.571h2.723c.868 0 1.572.704 1.572 1.571v4.352c0 .301.325.49.588.341l3.835-2.19a1.571 1.571 0 0 1 2.137.571l.983 1.686c.44.754.18 1.723-.578 2.155l-3.802 2.173a.393.393 0 0 0 0 .682l3.802 2.173a1.572 1.572 0 0 1 .578 2.155Z"
            clipRule="evenodd"
        />
    </svg>
);
const ForwardRef = forwardRef(ReferenceIcon);
export default ForwardRef;
