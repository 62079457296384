import React, { forwardRef } from 'react';
import { twMerge, twJoin } from 'tailwind-merge';
import {
    ReviewFilledIcon,
    ReviewUnfilledIcon,
    ReviewHalfIcon,
    ReferenceIcon,
    ReferenceHalfIcon,
    ReferenceOutlineIcon,
} from '../../atoms/icons';

type Variants = 'default' | 'inverted';

type Kinds = 'review' | 'reference';

type Sizes = 'sm' | 'md';

export type Props = Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> & {
    value: number;
    max?: number;
    count?: number;
    variant?: Variants;
    kind?: Kinds;
    size?: Sizes;
};

const variants: Record<
    Variants,
    { filled: string; unfilled: string; count: string }
> = {
    default: {
        filled: 'text-grey-700',
        unfilled: 'text-grey-500',
        count: 'text-grey-700',
    },
    inverted: {
        filled: 'text-highlight-700',
        unfilled: 'text-highlight-500',
        count: 'text-grey-700',
    },
};

const icons: Record<
    Kinds,
    {
        filled: React.ComponentType<React.ComponentPropsWithRef<'svg'>>;
        half: React.ComponentType<React.ComponentPropsWithRef<'svg'>>;
        unfilled: React.ComponentType<React.ComponentPropsWithRef<'svg'>>;
    }
> = {
    review: {
        filled: ReviewFilledIcon,
        half: ReviewHalfIcon,
        unfilled: ReviewUnfilledIcon,
    },
    reference: {
        filled: ReferenceIcon,
        half: ReferenceHalfIcon,
        unfilled: ReferenceOutlineIcon,
    },
};

const sizes: Record<
    Sizes,
    { wrapper: string; container: string; icon: string; count: string }
> = {
    sm: {
        wrapper: 'gap-x-2',
        container: 'gap-1',
        icon: 'h-4 w-4',
        count: 'font-semibold text-sm',
    },
    md: {
        wrapper: 'gap-x-2',
        container: 'gap-1',
        icon: 'h-5 w-5',
        count: 'font-semibold text-md',
    },
};

const Rating = forwardRef<HTMLDivElement, Props>(
    (
        {
            value,
            max = 5,
            count,
            variant = 'default',
            kind = 'review',
            size = 'md',
            className,
            ...rest
        },
        ref
    ) => {
        const sizeClasses = sizes[size];
        const variantClasses = variants[variant];
        const icon = icons[kind];
        const iconFilledClassNames = twJoin(
            sizeClasses.icon,
            variantClasses.filled
        );
        const iconUnfilledClassNames = twJoin(
            sizeClasses.icon,
            variantClasses.unfilled
        );
        const countClassNames = twJoin(variantClasses.count, sizeClasses.count);
        const classNames = twMerge(
            'flex items-center',
            sizeClasses.wrapper,
            className
        );

        const finalValue =
            typeof value === 'number' && !Number.isNaN(value) ? value : 0;

        return (
            <div ref={ref} className={classNames} {...rest}>
                <div className={twJoin('flex', sizeClasses.container)}>
                    {Array.from({ length: max }).map((_, index) => {
                        if (index < Math.floor(finalValue)) {
                            return (
                                <icon.filled className={iconFilledClassNames} />
                            );
                        }
                        if (index >= finalValue) {
                            return (
                                <icon.unfilled
                                    className={iconUnfilledClassNames}
                                />
                            );
                        }

                        return <icon.half className={iconFilledClassNames} />;
                    })}
                </div>
                {typeof count === 'number' && !Number.isNaN(count) ? (
                    <span className={countClassNames}>{count}</span>
                ) : null}
            </div>
        );
    }
);

export default Rating;
