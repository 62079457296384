/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const ReferenceHalfIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11.5 23h-1.171a1.572 1.572 0 0 1-1.571-1.571v-4.352a.391.391 0 0 0-.588-.342l-3.836 2.193a1.572 1.572 0 0 1-2.137-.573l-.983-1.686a1.57 1.57 0 0 1 .578-2.156l3.802-2.172a.388.388 0 0 0 .195-.385.388.388 0 0 0-.195-.297L1.792 9.486a1.57 1.57 0 0 1-.578-2.156l.983-1.685a1.571 1.571 0 0 1 2.137-.572L8.17 7.264a.401.401 0 0 0 .195.052.393.393 0 0 0 .393-.393V2.57c0-.867.704-1.571 1.572-1.571h2.724c.868 0 1.571.704 1.571 1.571v4.352a.393.393 0 0 0 .588.341l3.835-2.19a1.57 1.57 0 0 1 2.137.572l.983 1.684c.44.756.18 1.724-.578 2.156l-3.802 2.172a.394.394 0 0 0 0 .684l3.802 2.171c.759.433 1.018 1.401.578 2.156l-.983 1.686a1.57 1.57 0 0 1-2.137.572l-3.835-2.192a.393.393 0 0 0-.588.342v4.352c0 .867-.703 1.571-1.572 1.571H11.5Zm0-1.466V2.466h1.553c.057 0 .104.048.104.105v4.352a1.862 1.862 0 0 0 2.782 1.615l3.835-2.192a.104.104 0 0 1 .142.038L20.9 8.07c.007.01.023.039.011.081a.1.1 0 0 1-.049.063l-3.801 2.172c-.578.33-.937.949-.937 1.615 0 .665.36 1.285.937 1.615l3.801 2.171a.1.1 0 0 1 .05.064.098.098 0 0 1-.012.08l-.983 1.686a.104.104 0 0 1-.09.052.096.096 0 0 1-.052-.015l-3.835-2.191a1.853 1.853 0 0 0-2.211.274c-.368.353-.571.83-.571 1.341v4.352a.105.105 0 0 1-.104.105H11.5Z"
            clipRule="evenodd"
        />
    </svg>
);
const ForwardRef = forwardRef(ReferenceHalfIcon);
export default ForwardRef;
